<template>
  <div class="shareProduct">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t.noMoreData"
      @load="onLoad"
    >
      <div class="list">
        <share-list :listProduct="listProduct"></share-list>
      </div>
    </van-list>
  </div>
</template>
<script>
import Vue from "vue";
import { Sticky, Row, Col, List } from "vant";
Vue.use(Sticky).use(Row).use(List).use(Col);
Vue.use(List).use(Sticky);

import ShareList from "./components/ShareList";
export default {
  name: "ShareProduct",
  components: {
    ShareList,
  },
  data() {
    return {
      active: 1,
      listProduct: [], //产品
      type: 1,
      pageIndex: 1, //页码,
      loading: true,
      finished: false,
    };
  },
  mounted() {
    //首次加载数据
    this.getShopProductsByPage();
  },
  methods: {
    changeType(index) {
      if (index == 0) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
          isParams: false,
          path: "focus/follow",
          name: "Follow",
          query: [],
        });
      }
    },
    // 首次加载数据
    getShopProductsByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      if (this.type !== 0 && this.type !== 1) {
        param = {
          PageIndex: 1,
        };
      } else {
        param = {
          PageIndex: 1,
          ScreenValues: this.type,
        };
      }
      this.$api.follow
        .loadShareProductByPage(param)
        .then((res) => {
          const data = res.data;
          this.listProduct = data;
          if (data === "" || data === null || data.length === 0) {
            this.finished = true;
          } else {
            this.finished = false;
          }
          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    //加载更多
    onLoad() {
      let param = {};
      this.finished = false;
      if (this.type !== 0 && this.type !== 1) {
        param = {
          PageIndex: this.pageIndex + 1,
        };
      } else {
        param = {
          PageIndex: this.pageIndex + 1,
          ScreenValues: this.type,
        };
      }
      this.$api.follow
        .loadShareProductByPage(param)
        .then((res) => {
          const data = res.data;
          if (data === "" || data === null || data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.listProduct = this.listProduct.concat(data);
            this.pageIndex = this.pageIndex + 1;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.shareProduct {
  width: 100%;
  font-size: 26px;
  .list {
    width: 100%;
  }
  .tabList {
    width: 100%;
    padding-top: 18px;
    text-align: center;
    background: -webkit-linear-gradient(left, #57c88c, #2ea897);
    .tabItem {
      width: 50%;
      text-align: center;
      color: #fff;
      .tabItemTotal {
        margin: 0;
        padding: 0;
      }
      .itemTop {
        @include puiblicFlex;
        text-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        .itemTopName {
          font-size: 16px;
          margin: 0;
          padding: 0;
        }
        .itemTopNum {
          margin: 0;
          padding: 0;
        }
      }
      .itemBorder {
        height: 4px;
        width: 30px;
        // background-color: #43cd9a;
        margin: 0 auto;
        border-radius: 16px;
        margin-top: 10px;
        opacity: 0;
      }
      .itemBorderAction {
        background-color: #fff;
        opacity: 1;
      }
    }
  }
}
</style>
